import React, { useState, useEffect } from "react";
import { Box, MenuItem, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import api from "../../services/api";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Select from 'react-select';
import { customStyles } from "./styles";
import { useNavigate } from "react-router-dom";

interface SelectedOption {
    label: string;
    value: number;
}

interface CheckProps {
    itemsCheck: string[];
    setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Technical: React.FC<CheckProps> = ({
    itemsCheck,
    setItemsCheck,
}) => {
    const [resetInputs, setResetInputs] = useState(false);
    const [selectedRoutineId, setSelectedRoutineId] = useState<string>('');
    const [selectedPrimaryStationId, setSelectedPrimaryStationId] = useState<string>('');
    const [selectedStationId, setSelectedStationId] = useState<string>('');
    const [numStations, setNumStations] = useState<number>(1);
    const [selectedStationsIds, setSelectedStationsIds] = useState<string[]>([]);
    const [isStationSelected, setIsStationSelected] = useState(false);
    const [technicalName, setTechnicalName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [technicalId, setTechnicalId] = useState<string>('');
    const [routines, setRoutines] = useState<any[]>([]);
    const [stations, setStations] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectKeys, setSelectKeys] = useState<number[]>([0]);
    const [selectKey, setSelectKey] = useState(0);

    const inputName: string[] = ["Nome", "Código do Usuário", "Senha", "Estação Principal", "Estação", "Rotinas"];
    const navigate = useNavigate();

    async function Technical() {
        try {
            const response = await api.post("/api/addTechnical", {
                name: technicalName,
                email: email,
                password: password,
                stationId: selectedPrimaryStationId
            });

            const technicalId = response.data.id;

            setTechnicalId(technicalId);



            const technicalStationArray = Object.values(selectedStationsIds).map((item) => ({
                technicalId: technicalId,
                stationId: item
            }));

            const technicalRoutineArray = selectedOptions.map((item: SelectedOption) => ({
                technicalId: technicalId,
                routineId: item.value,
            }));

            const responseTechnicalStation = await api.post("/api/addTechnicalStation", technicalStationArray);

            const responseTechnicalRoutine = await api.post("/api/addTechnicalRoutine", technicalRoutineArray);

            if (response.status === 200 && responseTechnicalStation.status === 200 && responseTechnicalRoutine.status === 200) {
                setSelectedOptions([]);
                setSelectedStationsIds([""]);

                setTechnicalName("");
                setEmail("");
                setPassword("");
                setSelectedRoutineId("");
                setSelectedStationId("");
                setSelectedPrimaryStationId("");


                toast.success("Usuário cadastrado com sucesso!");
            } else {
                toast.error("Erro ao cadastrar um usuário, tente novamente!");
            }




        } catch (error: any) {
            console.error('Erro forte', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                setStations(responseStation.data);
            } catch (error: any) {
                console.error('Error fetching equipment data:', error);
            }
        };

        fetchData();
    }, [resetInputs]);

    const loadRoutinesByStation = async (station_id: string, index: number) => {
        try {
            const responseStationRoutines = await api.get<{ id: string, name: string }[]>(`/api/getAllStationRoutine/${station_id}`);
            const stationRoutines = responseStationRoutines.data;

            setRoutines((prevRoutines) => {
                const updatedRoutines = [...prevRoutines];
                updatedRoutines[index] = stationRoutines;
                return updatedRoutines;
            });

        } catch (error: any) {
            console.error("Error fetching routines by station:", error);
        }
    };

    const handleSelectChange = (selectedOptions: any) => {
        setSelectedOptions(selectedOptions);
    };

    const handleStationChange = async (selectedValue: string, index: number) => {
        const isStationAlreadySelected = selectedStationsIds.includes(selectedValue);

        if (!isStationAlreadySelected) {
            setSelectedStationsIds((prevSelectedIds) => {
                const updatedIds = [...prevSelectedIds];
                updatedIds[index] = selectedValue;
                return updatedIds;
            });
            setSelectKey((prevKey) => prevKey + 1);

            setSelectedOptions([]);
            setIsStationSelected(true);

            loadRoutinesByStation(selectedValue, index);
        } else {
            toast.error("A estação já está selecionada em outra estação");
        }
    };

    const addStationDropdown = () => {
        setNumStations((prevNumStations) => prevNumStations + 1);

        const updatedIds = [...selectedStationsIds];
        updatedIds.push('');

        setSelectedStationsIds(updatedIds);

        setRoutines((prevRoutines) => [...prevRoutines, []]);
    };

    const removeStationDropdown = (index: number) => {
        setNumStations((prevNumStations) => prevNumStations - 1);

        const updatedIds = [...selectedStationsIds];
        updatedIds.splice(index, 1);

        setSelectedStationsIds(updatedIds);
    };


    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: '100%',
                    borderWidth: 10,
                    borderColor: '#efefef',
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[0].charAt(0).toUpperCase()}${inputName[0].slice(1)}`}
                        size='small'
                        value={technicalName}
                        onChange={(e) => setTechnicalName(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[1].charAt(0).toUpperCase()}${inputName[1].slice(1)}`}
                        size='small'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[2].charAt(0).toUpperCase()}${inputName[2].slice(1)}`}
                        size='small'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        select
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[3].charAt(0).toUpperCase()}${inputName[3].slice(1)}`}
                        value={selectedPrimaryStationId}
                        onChange={(e) => setSelectedPrimaryStationId(e.target.value)}
                        size='small'
                        style={{ marginBottom: '20px' }}
                    >
                        {stations.map((station) => (
                            <MenuItem key={station.id} value={station.id}>
                                {station.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#111c5d', padding: 2, borderRadius: 3, width: '500px', flexDirection: 'column', position: 'relative' }}>
                        <span style={{ position: 'absolute', top: -14, left: 10, backgroundColor: '#FFF', padding: '0 5px', color: '#111c5d', zIndex: 1 }}>
                            Rotinas do Usuário
                        </span>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                            {[...Array(numStations)].map((_, index) => (
                                <div key={index} style={{ marginBottom: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Box sx={{ flexDirection: 'column' }}>
                                            <TextField
                                                key={selectKey}
                                                select
                                                sx={{ minWidth: '400px', maxWidth: '45%' }}
                                                label={`${inputName[4].charAt(0).toUpperCase()}${inputName[4].slice(1)}`}
                                                value={selectedStationsIds[index]}
                                                onChange={(e) => handleStationChange(e.target.value as string, index)}
                                                size='small'
                                            >
                                                {stations.map((station) => (
                                                    <MenuItem key={station.id} value={station.id}>
                                                        {station.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {index > 0 && (
                                                <IconButton
                                                    onClick={() => removeStationDropdown(index)}
                                                    color="secondary"
                                                    style={{ marginLeft: 10, padding: 6 }}
                                                >
                                                    <DeleteIcon sx={{ color: 'red' }} />
                                                </IconButton>
                                            )}
                                        </Box>

                                    </div>
                                    {routines[index] && (
                                        <Select
                                            key={selectKey}
                                            isMulti
                                            options={routines[index].map((item: { routine_id: string, name: string }) => ({
                                                value: item.routine_id,
                                                label: item.name
                                            }))}
                                            styles={customStyles}
                                            onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                                            placeholder={inputName[5]}
                                            value={selectedOptions}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        <IconButton
                            onClick={addStationDropdown}
                            color="primary"
                            style={{ marginTop: 10, alignSelf: 'center' }}
                        >
                            <AddIcon sx={{ color: '#111c5d' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
                        <button
                            onClick={Technical}
                            type="button"
                            className="btn btn-primary"
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                            style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
                        >
                            <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
                        </button>
                        <button
                            onClick={() => navigate(`/home`)}
                            type="button"
                            className="btn btn-primary"
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                            style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
                        >
                            <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
                        </button>
                    </Box>

                </Box>
            </Box>
        </>
    );
};