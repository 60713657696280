import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import api from "../../services/api";


export default function NewRegister() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [birth, setBirth] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");


    async function NewRegister() {
        try {
            const response = await api.post("saveUser", {
                username,
                birth,
                email,
                password,
              });
              if (password === confirmPassword) {
                navigate("/");
                
            } else {
                setError("As senhas não coincidem.");
            }
              
        } catch (error: any) {  
            setError("Ocorreu um erro ao fazer o registro. Por favor, tente novamente mais tarde.");
        }   
  }

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2">Cadastrar</h2>
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicUserName">
                        <Form.Label className="text-center">Usuário</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Digite seu nome"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicBirth">
                        <Form.Label className="text-center">Data de Nascimento</Form.Label>
                        <Form.Control
                          type="date"
                          value={birth}
                          onChange={(e) => setBirth(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Senha"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirmar Senha</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirmar senha"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group>
                      
                      <div className="d-grid">
                        <Button variant="primary" onClick={NewRegister}>
                          Cadastre-se
                        </Button>
                      </div>
                    </Form>
                    {error && (
                      <div className="mt-3 text-danger">
                        <p>{error}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}