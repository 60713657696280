import "bootstrap/dist/css/bootstrap.css";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, TextField } from "@mui/material";
import { Body } from "../../components/Body";
import api from "../../services/api";
import { Modal, Table } from "react-bootstrap";
import { Key, useEffect, useState } from "react";
import { Paper, Button } from "@mui/material";
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import React from "react";
import { RoutineHistory } from "../RoutineHistory";
import "bootstrap/dist/css/bootstrap.css";
import MapContainer from "../../components/Map";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { toast } from "react-toastify";

interface CardTableProps {
    data: any[];
}

interface LatLng {
    lat: number,
    lng: number
}

export const HomeDetails: React.FC<CardTableProps> = () => {
    const [activityData, setActivityData] = useState<any[]>([]);
    const [observation, setObservation] = useState<string>('');
    const [routineId, setRoutineId] = useState<string>('');
    const [stationId, setStationId] = useState<string>('');
    const [technicalId, setTechnicalId] = useState<string>('');
    const [openCollapse, setOpenCollapse] = useState<number | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showWeekdaysMap, setShowWeekdaysMap] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [days, setDay] = useState<string>('');
    const [mapVisible, setMapVisible] = useState<boolean>(false);
    const [positions, setPositions] = useState<any>();
    console.log("🚀 ~ positions:", positions)
    const [activities, setActivities] = useState<any>();
    const [checkedData, setCheckedItemData] = useState<any>();
    const location = useLocation();
    const [mapContent, setMapContent] = useState<React.ReactNode | null>(null);
    const [extractedRoutineId, setExtractedRoutineId] = useState<string>('');
    const [extractedStationId, setExtractedStationId] = useState<string>('');
    const [extractedTechnicalId, setExtractedTechnicalId] = useState<string>('');

    const weekdays: string[] = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
    const formatDateTime = (dateTimeString: string | number | Date) => {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleString('pt-BR', options);
    };
    const data = location.state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlParts = window.location.href.split('/');
                const extractedRoutineId = urlParts[urlParts.length - 3];
                const extractedStationId = urlParts[urlParts.length - 2];
                const extractedTechnicalId = urlParts[urlParts.length - 1];

                setRoutineId(extractedRoutineId)
                setStationId(extractedStationId)
                setTechnicalId(extractedTechnicalId)

                const responseJson = await api.get(`/api/getAllActivityByRoutine/${extractedRoutineId}/${extractedStationId}/${extractedTechnicalId}`);
                const responseData = responseJson.data;
                console.log("🚀 ~ fetchData ~ responseData:", responseData)

                const checkedItems = await api.get(`/api/searchCheckedItems/${responseData[0].activity_id}/${responseData[0].equipment_id}/${extractedRoutineId}`);
                const checkedItemsData = checkedItems.data;
                console.log("🚀 ~ fetchData ~ checkedItemsData:", checkedItemsData)

                setCheckedItemData(checkedItemsData);

                const formattedData = responseData.map((item: any) => {
                    if (item.activity_type == "Manutenção") {
                        const activityStatus = calculateActivityStatus(checkedItemsData, item.weekday_id)
                        return {
                            Atividade: item.activity_name,
                            TipoAtividade: item.activity_type,
                            Status: activityStatus,
                            Data: item.weekday,
                            Dia: item.weekday_id,
                            Horário: item.time,
                            Items: checkedItemsData,
                            Items2: item.items,
                            Equipamento: item.equipment_name,
                            TipoEquipamento: item.equipment_type,
                            ItemStatus: checkedItemsData,
                            DataFinal: item.updated_at,
                            Observação: setObservation(item.observations)
                        };
                    } else {
                        return {
                            Atividade: item.activity_name,
                            TipoAtividade: item.activity_type,
                            Status: item.checked_activity,
                            Data: item.weekday,
                            Horário: item.time,
                            Equipamento: item.equipment_name,
                            TipoEquipamento: item.equipment_type,
                            ItemStatus: item.checked_item,
                            DataFinal: item.updated_at,
                            Observação: setObservation(item.observations)
                        };
                    }

                })
                setActivityData(formattedData)
            } catch (error: any) {
                console.error('Error fetching equipment data:', error);
            }
        };

        fetchData();
    }, [setRoutineId, setStationId, setTechnicalId]);

    const toggleCollapse = (index: number) => {
        setOpenCollapse((prev) => (prev === index ? null : index));
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };
    const handleButtonMap = async (day: string, index: number) => {
        setShowWeekdaysMap(false);
        setPositions(null);
        setActivities(null);
        setDay(day);
        try {
            await buildAndShowMap(index);
        } catch (error) {
            console.error(error);
        }
    };


    const buildAndShowMap = async (index: number) => {
        try {
            index++;
            const responseJson = await api.get(`/api/getAllMapPoints/${index}/${routineId}/${stationId}/${technicalId}`);
            const responseData = responseJson.data;
            console.log("🚀 ~ buildAndShowMap ~ responseData:", responseData)
            if (!responseData || responseData.length === 0 || !responseData.data || !responseData.data.positions) {
                setMapVisible(false);
                setShowAlertModal(true)
            } else {

                const positions = responseData.data.positions;
                const activityPositionsArray = responseData.data.activityPositions;
                showGoogleMap(positions, activityPositionsArray);
                setShowWeekdaysMap(true);
                setMapVisible(true);
            }
        } catch (error) {
            console.error('Error building map:', error);
        }
    };


    const handleCloseMap = () => {
        setMapVisible(false);
    };

    const showGoogleMap = async (positions: any, activityPositions: any) => {
        if (!positions || !activityPositions) {
            console.error('Positions / activityPositions está vazio');
            return null;
        }

        try {
            const positionsCoordinates = positions[0].map((position: { latitude: any; longitude: any }) => ({
                lat: position.latitude,
                lng: position.longitude
            }));
            console.log("🚀 ~ positionsCoordinates ~ positionsCoordinates:", positionsCoordinates)

            setPositions(positionsCoordinates)

            const activityCoordinates = activityPositions[0].map((position: {
                latitude: any;
                longitude: any,
                status: any
                nome: any
            }) => ({
                lat: position.latitude,
                lng: position.longitude,
                status: position.status,
                nome: position.nome
            }));

            setActivities(activityCoordinates)

            setShowWeekdaysMap(true)
        } catch (error) {
            console.error('Error parsing positions or activityPositions:', error);
            return null;
        }
    };

    const calculateActivityStatus = (items: any[], weekday_id: number): string | undefined => {
        console.log("🚀 ~ calculateActivityStatus ~ weekday_id:", weekday_id)
        console.log("🚀 ~ calculateActivityStatus ~ items:", items)
        // Filtra os itens para o dia da semana especificado
        const itemsForWeekday = items.filter(item => item.weekday_id === weekday_id);

        // Verifica se há itens para o dia da semana fornecido
        if (itemsForWeekday.length === 0) return 'No data for the specified weekday';

        const allNull = itemsForWeekday.every(item => item.checked_item === null);
        const allZero = itemsForWeekday.every(item => item.checked_item === 0);
        const allOutside123 = itemsForWeekday.every(item => ![1, 2, 3].includes(item.checked_item));

        if (allNull) return;
        if (allZero) return 'red';
        if (allOutside123) return 'yellow';

        return 'green';
    };

    const renderTable = (filteredData: any[], title: string) => {
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', maxWidth: '92%', backgroundColor: '#ffffff', marginLeft: '50px', marginBottom: '300px', paddingBottom: '50px', boxShadow: '0.5px 0.5px 0.5px grey', borderRadius: '5px', flexDirection: 'column' }}>
                    <div style={{ marginLeft: '40px', marginRight: '20px', marginTop: '40px', textAlign: 'start', marginBottom: '-10px', fontWeight: 'bold', fontSize: 'x-large' }}><p>{title}</p></div>
                    <Table size="medium" aria-label="sticky table">
                        <TableBody>
                            {activityData.map((item: any, index: number) => {
                                const filteredItems = checkedData.filter((subitem: any) => subitem.weekday_id == item.Dia);
                                return title === 'Geral' ? (
                                    <React.Fragment key={index}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <div style={{ marginLeft: '30px' }}>
                                                <TableCell style={{ width: '75vw' }}>
                                                    <FormatListBulletedIcon sx={{ fontSize: 'medium', marginRight: '5px', color: '#001eb1' }} />
                                                    {item.Status === 0 ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '5px', color: '#001eb1' }} />
                                                            <strong>Data Prevista </strong>{item.Data + ' - ' + item.Horário + " | Data de conclusão (" + formatDateTime(item.DataFinal) + ")"}
                                                            <CancelIcon sx={{ color: `red`, marginLeft: 2 }} />
                                                        </>
                                                    ) : item.Status === 1 ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data Prevista </strong>{item.Data + ' - ' + item.Horário + " | Data de conclusão (" + formatDateTime(item.DataFinal) + ")"}
                                                            <CheckCircleIcon sx={{ color: `green`, marginLeft: 2 }} />
                                                        </>
                                                    ) : item.Status === 2 ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data Prevista </strong>{item.Data + ' - ' + item.Horário}
                                                            <ReportProblemIcon sx={{ color: `#FF8000`, marginLeft: 2 }} />
                                                        </>
                                                    ) :
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '5px', color: '#001eb1' }} />
                                                            <strong>Data Prevista </strong>{item.Data + ' - ' + item.Horário}
                                                            <SyncProblemIcon sx={{ color: `orange`, marginLeft: 2 }} />
                                                        </>
                                                    }
                                                </TableCell>
                                            </div>
                                        </TableRow>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={index}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '30px' }}>
                                                <div>
                                                    <FormatListBulletedIcon sx={{ fontSize: 'medium', marginRight: '5px', color: '#001eb1' }} />
                                                    {item.Status == "red" ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data de Prevista : </strong>{item.Data + ' - ' + item.Horário}
                                                            <EventAvailableIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data de Conclusão : </strong>{formatDateTime(item.DataFinal)}
                                                            <CancelIcon sx={{ color: `red`, marginLeft: 2 }} />
                                                        </>
                                                    ) : item.Status == "green" ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data Prevista : </strong>{item.Data + ' - ' + item.Horário}
                                                            <EventAvailableIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data de Conclusão : </strong>{formatDateTime(item.DataFinal)}
                                                            <CheckCircleIcon sx={{ color: `green`, marginLeft: 2 }} />
                                                        </>
                                                    ) : item.Status == "orange" ? (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data Prevista : </strong>{item.Data + ' - ' + item.Horário}
                                                            <ReportProblemIcon sx={{ color: `#FF8000`, marginLeft: 2 }} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>{item.Atividade}</strong>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'medium', marginLeft: '15px', marginRight: '3px', color: '#001eb1' }} />
                                                            <strong>Data Prevista : </strong>{item.Data + ' - ' + item.Horário}
                                                            <SyncProblemIcon sx={{ color: `orange`, marginLeft: 2 }} />
                                                        </>
                                                    )}
                                                </div>
                                                <Button
                                                    sx={{ height: '35px', backgroundColor: '#001eb1', marginLeft: 'auto', marginRight: '30px', paddingLeft: '35px', paddingRight: '35px', borderRadius: '10px', textTransform: 'none' }}
                                                    variant="contained"
                                                    size="small"
                                                    type="button"
                                                    onClick={() => toggleCollapse(index)}
                                                >
                                                    Visualizar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        {openCollapse === index && (
                                            <>
                                                {filteredItems.length > 0 ? (
                                                    filteredItems.map((subitem: any, subindex: any) => (
                                                        <Box key={subindex} sx={{ padding: 1, marginLeft: '50px' }}>
                                                            {subitem.item_name + ' - ' + item.Equipamento}
                                                            {subitem.checked_item === null ? (
                                                                <ReportProblemIcon sx={{ color: '#FF8000 !important', fontSize: '35px' }} />
                                                            ) : subitem.checked_item === 0 ? (
                                                                <CancelIcon sx={{ color: 'red !important', fontSize: '35px' }} />
                                                            ) : subitem.checked_item === 1 ? (
                                                                <CheckCircleIcon sx={{ color: 'green !important', width: 35, height: 35 }} />
                                                            ) : subitem.checked_item === 2 ? (
                                                                <ReportProblemIcon sx={{ color: '#FF8000 !important', fontSize: '35px' }} />
                                                            ) : (
                                                                <SyncProblemIcon sx={{ color: 'orange !important', fontSize: '35px' }} />
                                                            )}
                                                        </Box>
                                                    ))
                                                ) : (
                                                    item.Items2.map((subitem: any, subindex: any) => (
                                                        <Box key={subindex} sx={{ padding: 1, marginLeft: '50px' }}>
                                                            {subitem.item_name + ' - ' + item.Equipamento}
                                                            {subitem.checked_item === null ? (
                                                                <ReportProblemIcon sx={{ color: '#FF8000 !important', fontSize: '35px' }} />
                                                            ) : subitem.checked_item === 0 ? (
                                                                <CancelIcon sx={{ color: 'red !important', fontSize: '35px' }} />
                                                            ) : subitem.checked_item === 1 ? (
                                                                <CheckCircleIcon sx={{ color: 'green !important', width: 35, height: 35 }} />
                                                            ) : subitem.checked_item === 2 ? (
                                                                <ReportProblemIcon sx={{ color: '#FF8000 !important', fontSize: '35px' }} />
                                                            ) : (
                                                                <SyncProblemIcon sx={{ color: 'orange !important', fontSize: '35px' }} />
                                                            )}
                                                        </Box>
                                                    ))
                                                )}
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Grid container direction="column" style={{ maxWidth: '72vw' }}>
                        <div style={{ marginLeft: '40px', marginRight: '20px', marginTop: '5px', textAlign: 'start', marginBottom: '-5px', fontWeight: 'bold', fontSize: 'x-large' }}><p>Mapa</p></div>
                        <Grid item container direction="row">
                            {weekdays.map((day, index: number = 1) => (
                                <Grid item key={day}>
                                    <Button
                                        sx={{ width: '70px', height: '35px', backgroundColor: '#001eb1', marginLeft: '40px', marginRight: '-15px', paddingLeft: '35px', paddingRight: '35px', borderRadius: '10px', textTransform: 'none' }}
                                        variant="contained"
                                        size="small"
                                        type="button"
                                        value={day}
                                        onClick={() => {
                                            handleButtonMap(day, index);
                                        }}
                                    >
                                        {day}
                                    </Button>
                                    {positions && activities && positions.length > 0 && activities.length > 0 ? (
                                        <Modal show={showWeekdaysMap} onHide={() => setShowWeekdaysMap(false)} backdrop={false}
                                            style={{ backdropFilter: 'blur(2px)' }} centered>
                                            <Modal.Dialog
                                                style={{
                                                    maxWidth: '80vw',
                                                    margin: 'auto',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Mapa de {days}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <MapContainer positions={positions} activities={activities} />
                                                </Modal.Body>
                                            </Modal.Dialog>
                                        </Modal>
                                    ) : (
                                        <Modal show={showWeekdaysMap} onHide={() => setShowWeekdaysMap(false)} backdrop={false}
                                            style={{ backdropFilter: 'blur(2px)' }} centered>
                                            <Modal.Dialog>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Mapa de {days}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>

                                                </Modal.Body>
                                            </Modal.Dialog>
                                        </Modal>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 5 }}>
                        <div style={{ marginLeft: '40px', marginRight: '20px', marginTop: '5px', textAlign: 'start', marginBottom: '-5px', fontWeight: 'bold', fontSize: 'x-large' }}><p>Observações da Rotina</p></div>
                        <TextField
                            sx={{ minWidth: '60vw', maxWidth: '75vw', marginLeft: '40px', backgroundColor: 'white' }}
                            value={observation}
                            rows={4}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>
                </div>
                <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)} backdrop={false}
                    style={{ backdropFilter: 'blur(2px)' }} centered>
                    <Modal.Dialog
                        style={{
                            maxWidth: '80vw',
                        }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Mapa de {days}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Esta rotina não inclui um mapa para o dia selecionado!</p>
                        </Modal.Body>
                    </Modal.Dialog>
                </Modal>
            </>
        );
    };

    const renderTables = () => {
        const groupedData: { [key: string]: any[] } = {};

        activityData.forEach((activity: any) => {
            if (!groupedData[activity.TipoAtividade]) {
                groupedData[activity.TipoAtividade] = [];
            }
            groupedData[activity.TipoAtividade].push(activity);
        });

        return Object.keys(groupedData).map((tipoAtividade: string, index: number) => (
            <React.Fragment key={index}>
                {renderTable(groupedData[tipoAtividade], tipoAtividade)}
            </React.Fragment>
        ));
    };

    return (
        <Body>
            <div style={{ margin: '15px 0 0 50px', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                <div>
                    {data.map((item: {
                        Rotina: string;
                        Data: string;
                    }, index: Key | null | undefined) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '86%',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <h1 className="fw-bold mb-2 text-black">
                                    {item.Rotina}
                                </h1>
                                <Button
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#001eb1',
                                        width: '12%',
                                        borderRadius: '10px',
                                        height: '45px',
                                        borderColor: '#001eb1',
                                        textTransform: 'none',
                                        marginRight: '-145px'
                                    }}
                                    onClick={handleButtonClick}
                                >
                                    Visualizar Histórico
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', marginRight: '80px', boxShadow: '0.1px 0.1px 0.1px grey', marginTop: '15px', borderRadius: '5px' }}>

                    {data.map((item: {
                        Estação: string;
                        Usuário: string;
                        Data: string;
                    }, index: Key | null | undefined) => (
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '50px' }}>
                            <FmdGoodOutlinedIcon sx={{ color: `#001eb1`, marginLeft: '40px' }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', textAlign: 'start', marginBottom: '0', fontWeight: 'bold' }}>Estação</p>
                            <p style={{ marginLeft: '-10px', marginRight: '60px', textAlign: 'start', marginBottom: '0' }}>{item.Estação}</p>
                            <PersonOutlineOutlinedIcon sx={{ color: `#001eb1` }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', textAlign: 'center', marginBottom: '0', fontWeight: 'bold' }}>Usuário</p>
                            <p style={{ marginLeft: '-10px', marginRight: '20px', textAlign: 'start', marginBottom: '10px' }}>{item.Usuário}</p>
                        </div>

                    ))}
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: '30px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>

                            <CheckCircleIcon sx={{ color: `#14a105` }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', textAlign: 'center', marginBottom: '0' }}>Dentro dos Padrões</p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <CancelIcon sx={{ color: `#e11717` }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0', textAlign: 'center' }}>Fora dos Padrões</p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <ReportProblemIcon sx={{ color: `#ffca02` }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0', textAlign: 'center' }}>Indisponível</p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <SyncProblemIcon sx={{ color: `orange` }} />
                            <p style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0', textAlign: 'center' }}>Pendente</p>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="fw-bold text-black" style={{ margin: '40px 0 -10px 50px', padding: '0' }}>Atividades da Semana</h2>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: 5 }}>
                {renderTables()}
            </Box>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Histórico da Rotina</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RoutineHistory data={routineId} />
                </Modal.Body>
            </Modal>
        </Body>
    );
};
