import React, { useState, useEffect } from "react";
import { Box, MenuItem, Button, TextField, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';

interface CheckProps {
  itemsCheck: string[];
  setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Routine: React.FC<CheckProps> = ({ itemsCheck, setItemsCheck }) => {
  const [day, setDay] = useState<string[]>([]);
  const [checkedDays, setCheckedDays] = useState<{ [key: string]: boolean }>({});
  const [stations, setStations] = useState<{ id: string; name: string }[]>([]);
  const [routineName, setRoutineName] = useState<string>('');
  const [selectedActivityId, setSelectedActivityId] = useState<string>('');
  const [activities, setActivities] = useState<any[]>([]);
  const [time, setTime] = useState<string>('');
  const [resetInputs, setResetInputs] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedActivityType, setSelectedActivityType] = useState<number | null>(null);
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [weekdayIds, setWeekdayIds] = useState<string[]>([]);
  const weekdays: string[] = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
  const activity = "Atividades";
  const name = "Nome";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
      const responseActivity = await api.get<any>("/api/getAllActivities");
      const responseRoutineActivity = await api.get("/api/getAllRoutineActivities");

      const linkedActivityIds = responseRoutineActivity.data.map((item: { activity_id: any; }) => item.activity_id);


      setStations(responseStation.data);
      setActivities(responseActivity.data);
    };

    fetchData();
  }, [resetInputs]);

  const handleActivityChange = async (e: { target: { value: any } }) => {
    const activityName = e.target.value;
    setSelectedActivity(activityName);

    const selectedActivity = activities.find((activity) => activity.name === activityName);

    if (selectedActivity) {

      if (selectedActivityType !== null && selectedActivity.type !== selectedActivityType) {
        toast.error("Não é permitido selecionar atividades de tipos diferentes.");
        setSelectedActivity("");

        return;
      }
      setSelectedActivityId(selectedActivity.id);
      setSelectedActivityType(selectedActivity.type);
      setActivityIds((prevIds) => [...prevIds, selectedActivity.id]);
    }
  };

  useEffect(() => {
    setTime('');
  }, [weekdayIds]);

  async function saveRoutine() {
    try {
      const responseRoutine = await api.post("/api/addRoutine", {
        name: routineName,
      });

      const routineId = responseRoutine.data.id;

      const responseRoutineType = await api.post("/api/addRoutineType", {
        routineId: routineId,
        typeId: 3
      });

      const RoutineActivityArray = activityIds.flatMap(activityId =>
        weekdayIds.map(weekdayId => ({
          activityId: activityId,
          weekdayId: weekdayId,
          routineId: routineId,
          time: time,
        }))
      );

      const responseRoutineActivity = await api.post("/api/addRoutineActivity", RoutineActivityArray);

      if (responseRoutine.status === 200 && responseRoutineType.status === 200 && responseRoutineActivity.status === 201) {
        setSelectedActivityId("");
        setRoutineName("");
        setItemsCheck([]);
        setActivityIds([]);
        setWeekdayIds([]);
        setSelectedActivityType(null);

        toast.success("Cadastro da rotina realizada com Sucesso");

        const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
        const responseActivity = await api.get<any>("/api/getAllActivities");
        const responseRoutineActivity = await api.get("/api/getAllRoutineActivities");

        const linkedActivityIds = responseRoutineActivity.data.map((item: { activity_id: any; }) => item.activity_id);

        const activitiesWithLinkInfo = responseActivity.data.map((activity: { id: any; }) => ({
          ...activity,
          isLinked: linkedActivityIds.includes(activity.id)
        }));

        setStations(responseStation.data);
        setActivities(responseActivity.data);
      } else {
        toast.error("Erro ao cadastrar uma rotina, por favor tente novamente!");
      }
    } catch (error: any) {
      console.error('Erro forte', error);
    }
  }

  const addDate = (newDay: string) => {
    const updatedDays = day.includes(newDay)
      ? day.filter((dia) => dia !== newDay)
      : [...day, newDay];

    setDay(updatedDays);

    setCheckedDays((prevCheckedDays) => ({
      ...prevCheckedDays,
      [newDay]: !prevCheckedDays[newDay],
    }));

    setWeekdayIds(updatedDays.map((dia) => String(weekdays.indexOf(dia) + 1)));
  };

  function removeItem(itemToRemove: string) {
    setItemsCheck((prevItems) => prevItems.filter((item) => item !== itemToRemove));
  }

  function validaItem() {
    if (day.length === 0) {
      toast.error("Selecione pelo menos um dia da semana.");
      return;
    }

    const newActivityDateTimeList: string[] = [];
    const newDateTimeSet: Set<string> = new Set();

    day.forEach((dia) => {
      const selectedTime = time;

      newActivityDateTimeList.push(selectedActivity + ' (' + dia + ' - ' + selectedTime + ')');
      newDateTimeSet.add(`${dia} - ${selectedTime}`);
    });

    const newDateTimeList: string[] = Array.from(newDateTimeSet);

    setItemsCheck((prevItems) => [...prevItems, ...newActivityDateTimeList]);
    setDay([]);
    setSelectedActivity("");
    setCheckedDays({});
  }

  return (
    <>
      <div>
        <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                label={`${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                size='small'
                value={routineName}
                onChange={(e) => setRoutineName(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'flex-start', flexDirection: "column" }}>
            <TextField
              select
              value={selectedActivity}
              onChange={handleActivityChange}
              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
              label={`${activity.charAt(0).toUpperCase()}${activity.slice(1)}`}
              size='small'
            >
              {activities.map((activity, index) => (
                <MenuItem key={index} value={activity.name} disabled={activity.isLinked}>
                  {activity.isLinked ? (
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {activity.name}
                        <LockIcon style={{ fontSize: '1rem', marginLeft: '5px', marginRight: '10px' }} />
                      </div>
                      <label style={{ color: 'red', fontSize: '13px' }}>Essa atividade já pertence a uma rotina!</label>
                    </div>
                  ) : (
                    activity.name
                  )}
                </MenuItem>

              ))}
            </TextField>
            <Box sx={{ display: "flex", justifyContent: 'flex-start', gap: 2, flexDirection: "row" }}>
              {weekdays.map((day) => (
                <div key={day}>
                  <label>
                    <input
                      type="checkbox"
                      checked={!!checkedDays[day]}
                      onClick={() => addDate(day)}
                    />
                    <p style={{ marginLeft: 1 }}>{day}</p>
                  </label>
                </div>
              ))}
              <TextField
                sx={{ minWidth: "100px", marginRight: '5px' }}
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
              <button
                onClick={validaItem}
                type="button"
                className="btn btn-primary"
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                style={{ backgroundColor: '#111c5d', width: '13%', height: '90%' }}
              >
                <p style={{ fontSize: 15, margin: 0 }}>Adicionar Atividades</p>
              </button>
            </Box>
          </Box>
          <Box style={{ display: "flex", flexWrap: "wrap", width: '50%' }}>
            {itemsCheck && itemsCheck.map((item, index) => (
              <Button
                key={index}
                sx={{
                  flexDirection: "column",
                  backgroundColor: "#111c5d",
                  ':hover': {
                    backgroundColor: '#122079',
                  },
                  margin: '5px 5px 0 0 '
                }}
                size='small'
                variant='contained'
                onClick={() => removeItem(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
            <button
              onClick={saveRoutine}
              type="button"
              className="btn btn-primary"
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
            </button>
            <button
              onClick={() => navigate(`/details/atribuir%20rotinas`)}
              type="button"
              className="btn btn-primary"
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
            </button>
          </Box>

        </Box>
      </div>
      <ToastContainer />
    </>
  );
};
