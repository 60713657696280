import { imageSizeNextage } from "../../style";
import { Box } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export default function Header() {
    const [image, setImage] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseImage = await api.get("/api/getAllImages/1");
                const responseData = responseImage.data[0].image

                const nextageLogoBase64 = `data:image/png;base64,${responseData}`;

                setImage(nextageLogoBase64);
            } catch (error: any) {
                console.error('Error fetching image:', error);
            }
        };

        fetchData();
    }, []);

    let { menuRota } = useParams();

    return (
        <Box
            sx={{
                height: "100px",
                backgroundColor: 'white',
                padding: "0 0 0 30px",
            }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100px",
                width: "100%",
                maxWidth: "97%"
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Button href='/home' style={{background: 'transparent', border: 'none', width: 'auto', height: 'auto'}}>
                        <HomeIcon sx={{ color: '#b6b6b6' }}  />
                    </Button>
                    <p style={{ fontSize: '18px', color: '#b6b6b6', fontWeight: '700', marginLeft: '-5px', marginTop: 17 }}>Início</p>
                    <ChevronRightIcon sx={{ color: '#b6b6b6' }} />
                    <p style={{ fontSize: '17px', color: '#1A2A92', fontWeight: '700', marginTop: 17 }}>{menuRota?.charAt(0).toUpperCase()}{menuRota?.slice(1)}</p>
                </Box>
                <img src={image} alt="Logo" style={imageSizeNextage}/>
            </Box>
        </Box>
    );
}
