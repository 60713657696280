import "bootstrap/dist/css/bootstrap.css";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from "@mui/material";
import { Body } from "../../components/Body";
import Select from 'react-select';
import { customStyles } from "./styles";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Paper, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export function Home() {
    const [stations, setStations] = useState<any[]>([]);
    const [technical, setTechnical] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [dataRoutine, setDataRoutine] = useState<any[]>([]);
    const [routineType, setRoutineType] = useState<any[]>([]);
    const [selectedStations, setSelectedStations] = useState<any[]>([]);
    const [selectedTechnical, setSelectedTechnical] = useState<any[]>([]);
    const [selectedDataRoutine, setSelectedDataRoutine] = useState<any[]>([]);
    const [selectedRoutineType, setSelectedRoutineType] = useState<any[]>([]);
    const [isUpdated, setIsUpdated] = useState(false); // Estado para controlar a atualização
    const navigate = useNavigate();

    const inputName: string[] = ["Estação", "Usuário", "Data", "Status"];

    const thead: string[] = ["Rotina", "Estação", "Usuário", "Status"];

    const formatDateTime = (dateTimeString: string | number | Date) => {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleString('pt-BR', options);
    };

    const handleVisualizarClick = (routine_id: number, station_id: number, technical_id: number) => {
        const selectedData = data.find((item) => item.routine_id === routine_id && item.station_id === station_id && item.technical_id === technical_id);
        navigate(`/homeDetails/${selectedData.routine_id}/${selectedData.station_id}/${selectedData.technical_id}`, { state: [selectedData] });
    }

    const handleFilter = async () => {
        try {
            const response = await api.post('/api/getAllDataFromRoutine', {
                stations: selectedStations,
                technicals: selectedTechnical,
                dataRoutine: selectedDataRoutine,
                routineTypes: selectedRoutineType,
            });

            const filteredData = response.data;
            const formattedData = filteredData.map((item: {
                [x: string]: any;
            }) => ({
                routine_id: item.routine_id,
                station_id: item.station_id,
                technical_id: item.technical_id,
                Rotina: item.routine_name,
                Estação: item.station_name,
                Usuário: item.technical_name,
                Data: item.created_at,
                Status: item.status
            }));

            setData(formattedData);
        } catch (error) {
            console.error('Error filtering data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Chama o endpoint que atualiza a tabela do banco de dados
                await api.post('/api/getAllDataFromRoutineWeekday');
                setIsUpdated(true); // Define o estado como atualizado após a chamada bem-sucedida

                const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                const responseTechnical = await api.get<{ id: string, name: string }[]>(`/api/getAllTechnical`);
                const responseRoutineTypes = await api.get<{ id: string, name: string }[]>(`/api/getAllRoutineTypes`);
                const responseJson = await api.post('/api/getAllDataFromRoutine');
                const responseData = responseJson.data;
                const formattedData = responseData.map((item: {
                    [x: string]: any;
                }) => ({
                    routine_id: item.routine_id,
                    station_id: item.station_id,
                    technical_id: item.technical_id,
                    Rotina: item.routine_name,
                    Estação: item.station_name,
                    Usuário: item.technical_name,
                    Data: formatDateTime(item.created_at),
                    Status: item.status,
                    status_id: item.status_id
                }));
                setData(formattedData);

                setTechnical(responseTechnical.data);
                setStations(responseStation.data);
                setRoutineType(responseRoutineTypes.data);
            } catch (error: any) {
                console.error('Error fetching equipment data:', error);
            }
        };

        // Apenas busca os dados se a atualização foi concluída
        if (isUpdated) {
            fetchData();
        } else {
            // Faz a chamada de atualização
            const updateData = async () => {
                try {
                    await api.post('/api/getAllDataFromRoutineWeekday');
                    setIsUpdated(true); // Define o estado como atualizado após a chamada bem-sucedida
                } catch (error: any) {
                    console.error('Error updating data:', error);
                }
            };
            updateData();
        }
    }, [isUpdated]); // Executa o efeito quando o estado `isUpdated` muda

    return (
        <Body>
            <Box sx={{ margin: '0 0 0 30px', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                <h2 className="fw-bold mb-2" style={{ margin: '20px 0 20px 0', padding: '0' }}>Rotinas</h2>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: 5, width: '100%' }}>
                    <Select
                        isMulti
                        styles={customStyles}
                        options={stations.map((station) => ({ value: station.id, label: station.name }))}
                        placeholder={inputName[0]}
                        onChange={(selectedOptions) => setSelectedStations(selectedOptions.map((option) => option.value))}
                    />
                    <Select
                        isMulti
                        styles={customStyles}
                        options={technical.map((technical) => ({ value: technical.id, label: technical.name }))}
                        placeholder={inputName[1]}
                        onChange={(selectedOptions) => setSelectedTechnical(selectedOptions.map((option) => option.value))}
                    />
                    <Button
                        sx={{
                            height: '38px',
                            backgroundColor: '#111c5d',
                            ':hover': {
                                backgroundColor: '#122079',
                            }
                        }}
                        variant="contained"
                        size="small"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleFilter()}
                    >
                        Filtrar
                    </Button>
                </Box>
                <TableContainer component={Paper} sx={{ maxHeight: '50vh', marginTop: 5, marginBottom: 2, width: '98%' }}>
                    <Table size="medium" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {thead.map((item, index) => (
                                    <TableCell key={index} style={{ width: '20%' }}>{item}</TableCell>
                                ))}
                                <TableCell>Ação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? (
                                data.map((item: any, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        {thead.map((label, labelIndex) => (
                                            <TableCell key={`${index}-${labelIndex}`}>{item[label]}</TableCell>
                                        ))}
                                        <TableCell key={`action-${index}`}>
                                            <Button
                                                sx={{
                                                    height: '25px',
                                                    backgroundColor: '#111c5d',
                                                    ':hover': {
                                                        backgroundColor: '#122079',
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleVisualizarClick(item.routine_id, item.station_id, item.technical_id)}
                                            >
                                                Visualizar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={thead.length + 1} style={{ textAlign: 'center' }}>
                                        Para aparecer os dados, você deve realizar o fluxo de cadastro. Para isso, clique no botão "Iniciar Fluxo" e cadastre uma estação.
                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: '38px',
                                                    backgroundColor: '#111c5d',
                                                    ':hover': {
                                                        backgroundColor: '#122079',
                                                    }
                                                }}
                                                onClick={() => navigate(`/details/estação`)}
                                            >
                                                Iniciar Fluxo
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Body>
    );
}
