import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import api from "../../services/api";

interface BodyProps {
    items: any;
    labels: string[];
    title: string;
    itemData: { [key: string]: any }[];
    onSave: (updatedData: Object) => void;
}

export const EditForm: React.FC<BodyProps> = ({ labels, itemData, title, onSave }) => {
    const [data, setData] = useState({});
    const [stations, setStations] = useState<{ id: string; name: string }[]>([]);
    const [equipments, setEquipments] = useState<{ id: string; name: string }[]>([]);
    const [equipmentType, setEquipmentType] = useState<{ id: string; name: string }[]>([]);
    const [activityType, setActivityType] = useState<{ id: string; name: string }[]>([]);
    var param: number = 1


    const handleSave = () => {
        onSave(data);
    };

    const handleChange = (label: string, value: string) => {
        setData((prevData) => ({
            ...prevData,
            [label]: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
            const responseActivityType = await api.get<{ id: string; name: string }[]>("/api/getAllActivityTypes");
            const responseEquipment = await api.get<{ id: string; name: string }[]>("/api/getAllEquipments");
            const responseEquipmentType = await api.get<{ id: string; name: string }[]>("/api/getAllTypes");

            setStations(responseStation.data);
            setActivityType(responseActivityType.data);
            setEquipments(responseEquipment.data);
            setEquipmentType(responseEquipmentType.data);
            param++
        };

        fetchData();
    }, [param === 1]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {itemData.map((dataItem, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    {labels.map((label) => (
                        <div key={label}>
                            {label === 'Estação' && (title === 'equipamentos' || title === 'usuário') ? (
                                <TextField
                                    select
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    label={`${label.charAt(0).toUpperCase()}${label.slice(1)}`}
                                    size='small'
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                >
                                    {stations.map((stations) => (
                                        <MenuItem key={stations.id} value={stations.id}>
                                            {stations.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : label === 'Tipo' && title === 'equipamentos' ? (

                                <TextField
                                    select
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    label={`${label.charAt(0).toUpperCase()}${label.slice(1)}`}
                                    size='small'
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                >
                                    {equipmentType.map((equipmentType) => (
                                        <MenuItem key={equipmentType.id} value={equipmentType.id}>
                                            {equipmentType.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : label === 'Tipo' && title === 'atividades' ? (
                                <TextField
                                    select
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    label={`${label.charAt(0).toUpperCase()}${label.slice(1)}`}
                                    size='small'
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                >
                                    {activityType.map((activityType) => (
                                        <MenuItem key={activityType.id} value={activityType.id}>
                                            {activityType.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : label === 'Equipamento' && title === 'atividades' ? (
                                <TextField
                                    select
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    label={`${label.charAt(0).toUpperCase()}${label.slice(1)}`}
                                    size='small'
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                >
                                    {equipments.map((equipments) => (
                                        <MenuItem key={equipments.id} value={equipments.id}>
                                            {equipments.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : (
                                <TextField
                                    label={label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
                                    size='small'
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                    sx={{ marginBottom: 2, width: '100%' }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <Button
                sx={{
                    backgroundColor: '#111c5d',
                    color: 'white',
                    ':hover': {
                        backgroundColor: '#122079',
                    }
                }}
                onClick={handleSave}
            >
                Salvar
            </Button>
        </Box>
    );
};
