import React, { useEffect, useState } from "react";
import { Register } from "../../components/Register";
import { List } from "../../components/List";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { Body } from "../../components/Body";
import { AssignRoutine } from "../AssignRoutine";
import logoNext from '../../assets/images/nextageLogo.png';
import logoNextA from '../../assets/images/taskviewLogo.svg'
import { AssignUsers } from "../AssignTechnicals";

export function Details() {
  const [screen, setScreen] = useState("Listar");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  let { menuRota }: { menuRota: string } = useParams() as { menuRota: string };
  let labelsCadastro: string[] = [];
  let labelsList: string[] = [];

  if (menuRota === "equipamentos") {
    labelsCadastro = ["equipment"];
    labelsList = ["Equipamento", "Estação", "Data", "Endereço"];
  } else if (menuRota === "usuário") {
    labelsCadastro = ["technical"];
    labelsList = ["Usuário", "Estação", "Data", "Email"];
  } else if (menuRota === "estação") {
    labelsCadastro = ["station"];
    labelsList = ["Estação", "Endereço", "Data"];
  } else if (menuRota === "home") {
    navigate('/home');
    return null;
  }

  if (menuRota === "rotinas") {
    labelsCadastro = ["checkRoutine"];
    labelsList = ["Rotina"];
  }

  if (menuRota === "atividades") {
    labelsCadastro = ['checkActivity'];
    labelsList = ["Atividade", "Tipo", "Data"];
  }








  return (
    (menuRota === "atribuir rotinas") ? (
      <Body>
        <AssignRoutine />
      </Body>

    ) : menuRota === "atribuir usuarios" ? (
      <Body>
        <AssignUsers />
      </Body>
    ) : (
      <Body>
        <Box
          sx={{
            padding: '0 0 0 30px',
            width: '98%',
            display: 'flex',
            flexGrow: "1",
            flexDirection: 'column',
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: '20px 0',
              }}
            >
              <h2 className="fw-bold mb-2" style={{ margin: '0', padding: '0' }}>
                {`${screen === "Cadastrar" ? 'Cadastrar' : ''} ${menuRota?.charAt(0).toUpperCase()}${menuRota?.slice(1)}`}
              </h2>

              <Button
                variant={"contained"}
                size='small'
                type="button" sx={{
                  backgroundColor: '#111c5d',
                  ':hover': {
                    backgroundColor: '#122079',
                  }
                }}
                onClick={() => { screen === 'Listar' ? setScreen("Cadastrar") : setScreen("Listar"); }}
              >
                {screen === "Cadastrar" ? 'Listar' : 'Cadastrar'}
              </Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              {screen === "Listar" && (
                <List labels={labelsList} tittle={`${menuRota}`} />
              )}
              {screen === "Cadastrar" && (
                <Register
                  labels={labelsCadastro}
                  tittle={`Cadastro ${menuRota}`} />
              )}
            </Box>
          </>
        </Box>
      </Body>
    )
  );
}
