import Select from 'react-select';

export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        display: 'flex',
        width: '400px',
        maxWidth: '400px',
        overflowX: 'auto'
    }),
    menu: (provided: any) => ({
        ...provided,
        width: '400px',
        maxWidth: '400px',
    }),
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        height: '30px',
        display: 'flex',
        alignItems: 'center',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: '#111c5d',   
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: 'red',
        ':hover': {
            color: 'black',
        },
    }),
}
