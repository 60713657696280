import "bootstrap/dist/css/bootstrap.css";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Card, TableHead } from "@mui/material";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import React from "react";

interface RoutineHistoryProps {
  data: string;
}

export const RoutineHistory: React.FC<RoutineHistoryProps> = ({ data }) => {
  const [atividadesPorData, setAtividadesPorData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseHistory = await api.get(`/api/getAllRoutineActivitiesFromHistory/${data}`);
        setAtividadesPorData(responseHistory.data);
      } catch (error) {
        console.error('Erro ao obter atividades por data:', error);
      }
    };

    fetchData();
  }, []);

  const renderAtividadesPorData = () => {
    const groupedActivities: { [date: string]: any[] } = {};

    atividadesPorData.forEach((activity) => {
      const date = activity.Data;

      if (!groupedActivities[date]) {
        groupedActivities[date] = [];
      }

      groupedActivities[date].push(activity);
    });

    return Object.keys(groupedActivities).map((date) => (
      <><Card key={date} sx={{ margin: '10px 0', width: '100%', marginTop: '20px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)' }}>
        <h2 style={{ marginLeft: '10px', marginTop: '5px' }}>{date}</h2>
        <TableContainer>
          <Table size="large">
            <TableHead>
              <TableRow>
                <TableCell>Atividade</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tipo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedActivities[date].map((activity) => (
                <TableRow key={activity.activity_name}>
                  <TableCell>{activity.activity_name}</TableCell>
                  <TableCell>
                    {activity.checked === 0 ? (
                      <CancelIcon sx={{ color: `red`, marginLeft: 2 }} />
                    ) : activity.checked === 1 ? (
                      <CheckCircleIcon sx={{ color: `green`, marginLeft: 2 }} />
                    ) : activity.checked === 2 ?
                      <ReportProblemIcon sx={{ color: `#FF8000`, marginLeft: 2 }} />
                      :
                      <SyncProblemIcon sx={{ color: `orange`, marginLeft: 2 }} />}
                  </TableCell>
                  <TableCell>{activity.activity_type_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      </>
    ));
  };

  return <Box>{renderAtividadesPorData()}</Box>;
};
