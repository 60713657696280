import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Modal from "react-bootstrap/Modal";
import api from "../../services/api";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import { EditForm } from "../EditForm";

interface ListProps {
  labels: string[];
  tittle: string;
}

export const List: React.FC<ListProps> = ({ labels, tittle }) => {
  const [data, setData] = useState<any[]>([]);
  const [itemData, setItemData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [labelArray, setLabelArray] = useState<string[]>([])
  const [stations, setStations] = useState<{ id: string; name: string }[]>([]);
  const [equipments, setEquipments] = useState<{ id: string; name: string }[]>([]);
  const [equipmentType, setEquipmentType] = useState<{ id: string; name: string }[]>([]);
  const [activityType, setActivityType] = useState<{ id: string; name: string }[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [equipmentId, setEquipmentId] = useState<any>('');
  const [showModal, setShowModal] = useState(false);
  let route = "";

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    if (tittle === "equipamentos") {
      route = "getAllEquipmentsFront";
      const inputName: string[] = ["Equipamento", "Código", "Latitude", "Longitude", "Tipo", "Estação", "Endereço", "Observações", "Curiosidades"]
      setLabelArray(inputName)
    } else if (tittle === "usuário") {
      route = "getAllTechnicalFront";
      const inputName: string[] = ["Usuário", "Email", "Estação"]
      setLabelArray(inputName)
    } else if (tittle === "estação") {
      route = "getAllStationFront";
      const inputName: string[] = ["Estação", "Endereço", "Latitude", "Longitude"]
      setLabelArray(inputName)
    } else if (tittle === "rotinas") {
      route = "getAllRoutineFront";
      const inputName: string[] = ["Rotina", "Observações"]
      setLabelArray(inputName)
    } else if (tittle === "atividades") {
      route = "getAllActivitiesFront";
      const inputName: string[] = ["Atividade", "Equipamento", "Tipo"]
      setLabelArray(inputName)
    }

    async function fetchData() {
      try {
        const responseJson = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${route}`
        );
        const dataJson = await responseJson.json();
        setData(dataJson);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [tittle]);

  function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  useEffect(() => {
    async function handleItems() {
      try {
        const responseJson = await api.post(`/api/getItemData/${selectedItem}/${removeAccents(tittle)}`);
        const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
        const responseActivityType = await api.get<{ id: string; name: string }[]>("/api/getAllActivityTypes");
        const responseEquipment = await api.get<{ id: string; name: string }[]>("/api/getAllEquipments");
        const responseEquipmentType = await api.get<{ id: string; name: string }[]>("/api/getAllTypes");

        setStations(responseStation.data);
        setActivityType(responseActivityType.data);
        setEquipments(responseEquipment.data);
        setEquipmentType(responseEquipmentType.data);
        const dataJson = await responseJson.data;
        setItemData(dataJson);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    handleItems();
  }, [selectedItem, tittle]);

  const navigate = useNavigate();

  const redirectToQrCodePage = (item: any) => {
    navigate(`/qrCode/${item.Id}`, { state: { item } });
  };

  const handleSearch = async () => {
    try {
      if (searchValue != null && searchValue != '') {
        const responseJson = await api.post(`/api/dataFilter/${removeAccents(tittle)}/${searchValue}`);
        setData(responseJson.data);
      } else {
        if (tittle === "equipamentos") {
          route = "getAllEquipmentsFront";
          const responseJson = await api.get(`/api/getAllEquipmentsFront`);
          setData(responseJson.data)
        } else if (tittle === "usuário") {
          route = "getAllTechnicalFront";
          const responseJson = await api.get(`/api/getAllTechnicalFront`);
          setData(responseJson.data)
        } else if (tittle === "estação") {
          route = "getAllStationFront";
          const responseJson = await api.get(`/api/getAllStationFront`);
          setData(responseJson.data)
        } else if (tittle === "rotinas") {
          route = "getAllRoutineFront";
          const responseJson = await api.get(`/api/getAllRoutineFront`);
          setData(responseJson.data)
        } else if (tittle === "atividades") {
          route = "getAllActivitiesFront";
          const responseJson = await api.get(`/api/getAllActivitiesFront`);
          setData(responseJson.data)
        }

      }

    } catch (error) {
      console.error("Error searching data:", error);
    }
  };

  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleDeleteItem = async (item: any) => {
    try {
      const responseJson = await api.post(`/api/deleteItemData/${item}/${removeAccents(tittle)}`);
      deleteData(item)
    } catch (error) {
      toast.error("O item não pode ser excluido pois ele está sendo referenciado à outro item!")
    }
  };

  const deleteData = (selectedItem: any) => {
    let index = 0
    let originalObj: any[] = data.filter((item: any) => item.id !== selectedItem)
    setData(originalObj)
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(0);
  };

  const handleSave = async (updatedData: any) => {
    const responseJson = await api.post(`/api/updateItemData/${selectedItem}/${removeAccents(tittle)}`, updatedData);

    if (updatedData['Estação']) {
      const station = stations.find(station => station.id === updatedData['Estação']);
      updatedData['Estação'] = station?.name || updatedData['Estação'];
    } else if (updatedData['Tipo'] && tittle === 'equipamentos') {
      const equipmentTypeItem = equipmentType.find(item => item.id === updatedData['Tipo']);
      updatedData['Tipo'] = equipmentTypeItem?.name || updatedData['Tipo'];
    } else if (updatedData['Tipo'] && tittle === 'atividades') {
      const activityTypeItem = activityType.find(item => item.id === updatedData['Tipo']);
      updatedData['Tipo'] = activityTypeItem?.name || updatedData['Tipo'];
    } else if (updatedData['Equipamento'] && tittle === 'atividades') {
      const equipmentItem = equipments.find(item => item.id === updatedData['Equipamento']);
      updatedData['Equipamento'] = equipmentItem?.name || updatedData['Equipamento'];
    }

    changeData(updatedData, selectedItem);
  };

  const changeData = (updatedData: any, selectedItem: any) => {
    setData((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === selectedItem) {
          return { ...item, ...updatedData };
        }
        return item;
      });
      return newData;
    });
    handleCloseEditModal();
  };

  function convertBase64ToPdf(base64: string) {


    // Converter Base64 para Blob
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Criar URL para o Blob
    const blobUrl = URL.createObjectURL(blob);

    // Atualizar o estado com a URL do Blob
    setPdfUrl(blobUrl);

    // Libera a URL do Blob quando o componente é desmontado
    return () => URL.revokeObjectURL(blobUrl);
  }

  const handleSearchEquipmentPdf = async (equipmentId: number) => {
    setEquipmentId(equipmentId);
    try {
      const response = await api.get(`/api/getPdfEquipmentId/${equipmentId}`);
      const documentBase64 = response.data.pdf;
      convertBase64ToPdf(documentBase64);
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao buscar Obs e Curiosidades:', error);
    }
  };

  const qrCodeContent = `${process.env.REACT_APP_QR_CODE_URL}/qrcodeInfos/${equipmentId}`;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquisa"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {labels.map((label) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
              <TableCell key={'Ação'}>Ação</TableCell>
              {tittle === 'equipamentos' && (
                <TableCell key={'QrCode1'}>{'QR Code'}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) && data.length !== 0 ? (
              data.map((item: any, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  {labels.map((value) => (
                    <TableCell key={item[value]}>{item[value]}</TableCell>
                  ))}
                  <TableCell key={`${tittle}${index}`}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Button
                          style={{ width: 'auto', height: 'auto' }}
                          onClick={() => handleEditClick(item.id)}
                        >
                          <EditIcon style={{ color: "#111c5d" }} />
                        </Button>
                      </div>
                      <div>
                        <Button style={{ width: 'auto', height: 'auto' }} onClick={() => handleDeleteItem(item.id)}>
                          <DeleteIcon style={{ color: "#111c5d" }} />
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                  {tittle === 'equipamentos' && (
                    <TableCell key={`QRCode${index}`}>
                      <Button
                        sx={{ height: '30px', backgroundColor: '#111c5d' }}
                        variant={'contained'}
                        size='small'
                        type='button'
                        className='btn btn-primary'
                        onClick={() => handleSearchEquipmentPdf(item.id)}
                      >
                        Gerar
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={labels.length + 2}>
                  A tabela não contém dados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal show={isEditModalOpen} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar {tittle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm items={selectedItem} labels={labelArray} title={tittle} itemData={itemData} onSave={handleSave} />
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop={false}
        style={{ backdropFilter: 'blur(2px)' }} centered>
        <Modal.Header closeButton>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QRCode value={qrCodeContent} size={200} />
        </Modal.Body>
      </Modal>
    </Box>
  );
};
