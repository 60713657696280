import React, { ReactNode, useEffect } from "react";
import { Box } from "@mui/material";
import { Toolbar } from "../Tollbar";
import api from "../../services/api";
import Header from "../Header";

interface BodyProps {
    children: ReactNode;
}

export const Body: React.FC<BodyProps> = ({ children }) => {


    return (
        <Box sx={{
            width: '100%',
            minHeight: "100vh",
            height: 'auto',
            display: "flex",
            backgroundColor: '#FAFAFA'


        }}>
            <Toolbar />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: '1',
                maxHeight: '100%'

            }}>
                <Header></Header>
                {children}


            </Box>


        </Box>
    )
}
